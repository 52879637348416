<script lang='ts'>
  import InputGroup from './InputGroup.svelte';
  import InputField from './InputField.svelte';
  import { createEventDispatcher, getContext } from 'svelte';
  import type { CompanyData } from '../api/models/FetchCompanyDataResponse';
  import sanitizeStreetNameNumber from '../utils/sanitizeStreetNameNumber';

  const dispatch = createEventDispatcher();

  export let prefilled: CompanyData;

  const translate = getContext('translate');
  const getSetting = getContext('setting');
  const getFormFieldSetting = getContext('formFieldSettings');
  const lang = getContext('language')();
</script>
<section class='customer-data'>
  <h3 class='base-headline customer-data__headline'>{translate('Company Data')}</h3>

  <div class='customer-data__fields'>
    <InputGroup headline={translate('Basic Information')}>
      {#if getFormFieldSetting('organizationName')}
        <InputField
            label={translate('Company Name')}
            required={getFormFieldSetting('organizationName').required}
            readonly={getFormFieldSetting('organizationName').readonly}
            value={prefilled?.organization.name || ''}
            id='organizationName'
            input={{ type: 'text', name: 'organizationName'}}
        />
      {/if}
      {#if getFormFieldSetting('organizationIdentifier')}
        <InputField
          label={translate('Organization Identifier')}
          required={getFormFieldSetting('organizationIdentifier').required}
          readonly={getFormFieldSetting('organizationIdentifier').readonly}
          value={prefilled?.organization.identifier || ''}
          id='organizationIdentifier'
          input={{ type: 'text', name: 'organizationIdentifier'}}
          on:change={e => dispatch('organizationIdentifier', e.target.value)}
        />
      {/if}
    </InputGroup>
    <InputGroup headline={translate('Address')}>
      {#if getFormFieldSetting('street')}
        <InputField
          label={translate('Street')}
          description={translate('Street name and number, residence in $n required.').replace('$n', getSetting('countryName')[lang])}
          required={getFormFieldSetting('street').required}
          readonly={getFormFieldSetting('street').readonly}
          value={
                prefilled?.organization.address
                ? sanitizeStreetNameNumber(prefilled.organization.address.addressLine1, prefilled.organization.address.addressLine2)
                : ''}
          id='street'
          input={{ type: 'text', name: 'street'}}
        />
      {/if}
      {#if getFormFieldSetting('postalCode')}
        <InputField
          label={translate('Postal Code')}
          required={getFormFieldSetting('postalCode').required}
          readonly={getFormFieldSetting('postalCode').readonly}
          value={prefilled?.organization.address?.postalCode || ''}
          id='postal-code'
          input={{ type: 'number', name: 'postalCode'}}
        />
      {/if}
      {#if getFormFieldSetting('city')}
        <InputField
          label={translate('City')}
          required={getFormFieldSetting('city').required}
          readonly={getFormFieldSetting('city').readonly}
          value={prefilled?.organization.address?.city || ''}
          id='city'
          input={{ type: 'text', name: 'city'}}
        />
      {/if}
    </InputGroup>
    <InputGroup headline={translate('Main Contact Person')}>
      {#if getFormFieldSetting('firstName')}
        <InputField
          label={translate('First Name')}
          required={getFormFieldSetting('firstName').required}
          readonly={getFormFieldSetting('firstName').readonly}
          value={prefilled?.mainContact?.firstName || ''}
          id='first-name'
          input={{ type: 'text', name: 'firstName' }}
        />
      {/if}
      {#if getFormFieldSetting('lastName')}
        <InputField
          label={translate('Surname')}
          required={getFormFieldSetting('lastName').required}
          readonly={getFormFieldSetting('lastName').readonly}
          value={prefilled?.mainContact?.lastName || ''}
          id='surname'
          input={{ type: 'text', name: 'lastName'}}
        />
      {/if}
      {#if getFormFieldSetting('email')}
        <InputField
          label={translate('E-Mail')}
          required={getFormFieldSetting('email').required}
          readonly={getFormFieldSetting('email').readonly}
          value={prefilled?.mainContact?.email || ''}
          id='email'
          input={{ type: 'email', name: 'email'}}
          on:change={e => dispatch('email', e.target.value)}
        />
      {/if}
      {#if getFormFieldSetting('phoneNumber')}
        <InputField
          label={translate('Phone Number')}
          required={getFormFieldSetting('phoneNumber').required}
          readonly={getFormFieldSetting('phoneNumber').readonly}
          value={prefilled?.mainContact?.phoneNumber || ''}
          id='phone'
          input={{ type: 'tel', name: 'phoneNumber'}}
        />
      {/if}
    </InputGroup>
  </div>



</section>
<style lang='scss' scoped>
  .customer-data {
    &__headline {
      margin-block-end: var(--space-l);
    }
  }
</style>
