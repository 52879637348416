import { getHeaders } from '../utils/getHeaders';
import type { FetchCoverOptionsResponse } from './models/FetchCoverOptionsResponse';

export type FetchCoverOptions = Promise<{
  status: 'success' | 'error';
  data?: FetchCoverOptionsResponse;
  error?: unknown;
}>;

export const fetchCoverOptions = async (config): FetchCoverOptions => {
  const { customerId, apis, auth, isLocal } = config;
  const baseUrl = apis.methods.getCoverOptions;
  const requestUrl = `${baseUrl}?id=${customerId}&product=TravelInsurance`;

  const headers = getHeaders(apis, auth);

  const requestOptions = {
    method: 'GET',
    headers: headers,
  };

  try {
    const response: any = await fetch(requestUrl, requestOptions);

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    isLocal && console.log(data);

    if (data.errors) {
      throw new Error(data.errors);
    }

    return {
      status: 'success',
      data: data,
    };
  } catch (error) {
    return {
      status: 'error',
      error: error,
    };
  }
};
