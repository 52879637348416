import type { FetchCompanyDataResponse } from './models/FetchCompanyDataResponse';
import { getHeaders } from '../utils/getHeaders';

export type FetchCompanyData = Promise<{
  status: 'success' | 'error';
  data?: FetchCompanyDataResponse;
  error?: unknown;
}>;

export const fetchCompanyData = async (config): FetchCompanyData => {
  const { customerId, apis, auth, isLocal } = config;
  const baseUrl = apis.methods.getCompanyData;
  const requestUrl = `${baseUrl}?id=${customerId}&product=TravelInsurance`;

  const headers = getHeaders(apis, auth);

  const requestOptions = {
    method: 'GET',
    headers: headers,
  };

  try {
    const response: any = await fetch(requestUrl, requestOptions);

    if (!response.ok) {
      throw new Error(`${response.status} ${response.statusText}`);
    }

    const data = await response.json();

    isLocal && console.log(data);

    if (data.errors) {
      throw new Error(data.errors);
    }

    return {
      status: 'success',
      data: data,
    };
  } catch (error) {
    return {
      status: 'error',
      error: error,
    };
  }
};
