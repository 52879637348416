<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { sendInsuranceDocuments } from '../api/sendInsuranceDocuments';
  import type { ApiRequestConfig } from '../api/models/ApiRequestConfig';

  export let config: ApiRequestConfig;
  export let proposalId: string;
  export let emails: string[];

  const dispatch = createEventDispatcher();
  const getSetting = getContext('setting');
  const lang = getContext('language')();
  const insuranceDocumentsConfig = getSetting('sendInsuranceDocuments');
  const title = insuranceDocumentsConfig.title.text[lang].toUpperCase();
  const description = insuranceDocumentsConfig.description.text[lang];
  const buttonText = insuranceDocumentsConfig.buttonText.text[lang].toUpperCase();
  const acceptedText = insuranceDocumentsConfig.confirmation.text[lang];

  let isAccepted = false;

  const onClick = () => {
    sendInsuranceDocuments(config, proposalId, emails.filter(elem => elem && elem !== null)).then((response) => {
      if (response.status === 'success') {
        isAccepted = true;
        dispatch('isAccepted', isAccepted);
      }
    });
  }
</script>
<h3>{title}</h3>
<p>{description}</p>
<button class="button" type="button" on:click={onClick} disabled={isAccepted}>
  {buttonText}
</button>
{#if isAccepted}
  <p>{acceptedText}</p>
{/if}