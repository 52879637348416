/**
 * Sometimes, the prefilled streetname or number are returning `null`. But as these two are concatenated, they'll be turned into strings. So we have to only concatenate them, when both things are not null, only return the streetname when streetname is given but number isn't, and in other cases, just return null, so that the input remains empty.
 */
export default function sanitizeStreetNameNumber(_name, _number) {
  if (_name && _number) {
    return `${_name} ${_number}`;
  } else if (_name && !_number) {
    return _name;
  } else {
    return null;
  }
}
