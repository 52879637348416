import { getContext } from 'svelte';

export const getPageTranslation = (key: string) => {
  const translations: Object[] = getContext('pageTranslations');
  const lang = getContext('language')();

  const item = translations.find((t) => t.key === key);

  if (item && item[lang]) {
    return item[lang];
  } else {
    console.warn(`No item with key "${key}" for language "${lang}" found`);
    return '';
  }
};
