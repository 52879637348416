import type { CountryApi } from '../api/models/ApiRequestConfig';
import { getUuid } from './getUuid';

export const getHeaders = (apis: CountryApi, auth: string) => {
  const headers = new Headers();
  headers.append('subscription-key', apis.auth.subscriptionKey);
  headers.append('correlationId', getUuid());
  headers.append('userPrincipalId', apis.auth.subscriptionKey);
  headers.append('Authorization', auth);
  headers.append('Content-Type', 'application/json');
  return headers;
}