import type { ApiRequestConfig } from './models/ApiRequestConfig';
import { getUuid } from '../utils/getUuid';
import { getHeaders } from '../utils/getHeaders';

export type SendInsuranceDocumentsResponse = Promise<{
  status: 'success',
  data: string
} | {
  status: 'error',
  error: Error
}>;

export const sendInsuranceDocuments = async (
  config: ApiRequestConfig,
  proposalId: string,
  recipients: string[]
): SendInsuranceDocumentsResponse => {
  const {customerId, apis, auth} = config;
  const baseUrl = apis.methods.sendInsuranceDocuments;
  const requestUrl = `${baseUrl}?product=TravelInsurance`;

  const headers = getHeaders(apis, auth);
  const body = {
    proposalId: proposalId,
    recipients: recipients
  };

  const response = await fetch(
    requestUrl,
    {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    }
  );

  return response.ok ? {
    status: 'success',
    data: response.statusText
  } : {
    status: 'error',
    error: Error(`${response.status} ${response.statusText}`)
  };
}